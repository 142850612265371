<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h4 class="pb-1">Kredit Saya: {{ credit }}</h4>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="gotoTopUpPage"
    >
      Top Up
    </b-button>
    &nbsp;
    
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="showModalAjukan()"
      v-if="statused && statused.verified == false"
    >
      Ajukan Verified
    </b-button>

    <b-modal ref="my-modal-form-verified" @ok="ajukanVerified()" ok-title="Ajukan" cancel-title="Batal" title="Ajukan Verified">
        <div class="d-block">
            <b-form @submit.prevent>
                <b-row>
                    <b-col cols="12">
                        <b-form-group
                        label="Nama Lengkap"
                        label-for="nama-lengkap"
                        label-cols-md="4"
                        >
                        
                        <b-form-input
                            id="nama-lengkap"
                            placeholder="Nama Lengkap"
                            v-model="name"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="Email"
                        label-for="email"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="email"
                            placeholder="Email"
                            v-model="email"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="No Handphone"
                        label-for="no-handphone"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="no-handphone"
                            placeholder="No Handphone"
                            v-model="no_handphone"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="Nama Bisnis"
                        label-for="nama-bisnis"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="nama-bisnis"
                            placeholder="Nama Bisnis"
                            v-model="nama_bisnis"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="No NPWP"
                        label-for="no-npwp"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="no-npwp"
                            placeholder="No NPWP"
                            v-model="no_npwp"
                            v-mask="'##.###.###.#-###.###'"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="Provinsi"
                        label-for="provinsi"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="provinsi"
                            placeholder="Provinsi"
                            v-model="provinsi"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="Kota"
                        label-for="kota"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="kota"
                            placeholder="Kota"
                            v-model="kota"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="Alamat"
                        label-for="alamat"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="alamat"
                            placeholder="Alamat"
                            v-model="alamat"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="Jenis Usaha"
                        label-for="jenis-usaha"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="jenis-usaha"
                            placeholder="Jenis Usaha"
                            v-model="jenis_usaha"
                            readonly
                        />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group
                        label="Nama Jenis Usaha"
                        label-for="nama-jenis-usaha"
                        label-cols-md="4"
                        >
                        <b-form-input
                            id="nama-jenis-usaha"
                            placeholder="Nama Jenis Usaha"
                            v-model="nama_jenis_usaha"
                            readonly
                        />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg, BModal, BForm, BRow, BCol, BFormGroup, BFormInput
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import axios from '@axios'
import {mask} from 'vue-the-mask'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput
  },
  directives: {
    Ripple,
    mask
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
      return {
          style1 : "width:320px",
          style2 : "width:150px",
          name: '',
          email: '',
          no_handphone: '',
          nama_bisnis: '',
          no_npwp: '',
          provinsi: '',
          kota: '',
          alamat: '',
          jenis_usaha: '',
          nama_jenis_usaha: '',
          credit: '',
          verified_status : false,
          statused:{}
      }
  },
  mounted() {
      let _ = this
      _.getCredit()
      _.getVerifed()
      let userData = JSON.parse(localStorage.getItem('userData'))
      let advertiser = userData.advertiser

      _.name = advertiser.firstName + ' ' + advertiser.lastName 
      _.email = userData.email
      _.no_handphone = userData.phoneNumber
      _.nama_bisnis = advertiser.companyName
      _.no_npwp = advertiser.npwp
      _.provinsi = advertiser.province
      _.kota = advertiser.city
      _.alamat = advertiser.address
      _.jenis_usaha = advertiser.companyType ? advertiser.companyType.name : '-'
      if ( advertiser.companyTypeNameID ) {
        if ( Number.isInteger(advertiser.companyTypeNameID) ) {
          console.log('isInteger true')
          _.nama_jenis_usaha = advertiser.companyTypeName.name
        } else {
          console.log('isInteger false')
          _.nama_jenis_usaha = advertiser.companyTypeNameID
        }
      } else if ( advertiser.companyTypeName ) {
        _.nama_jenis_usaha = advertiser.companyTypeName.name
      } else {
        _.nama_jenis_usaha = '-'
      }
      _.credit = 0
      _.verified_status = userData.verified
  },
  methods: {
    kFormatter,
    showModalAjukan() {
        let _ = this
        _.$refs['my-modal-form-verified'].show()
    },
    gotoTopUpPage() {
        let _ = this
        _.$router.push({name: 'top-up-credit'})
    },
    ajukanVerified() {
        let _ = this
        axios.post('/advertiser/request-verified')
            .then(resp => {
                // console.log(resp)
                _.$emit('updateTable')
                _.$bvToast.toast('Pengajuan berhasil dikirim', {
                    title: "Sukses",
                    variant: "success",
                    solid: true,
                })
            })
            .catch(err => {
                console.log(err.response)
            })
    },
    getCredit()
    {
      let _ = this;
      let url  = '/advertiser/credit/';
      axios.get(url).then((response) => {
        if (response) {
          this.credit = response.data.data.creditBalance_cast;
          // console.log(this.credit);
        }
      });
    },
    getVerifed()
    {
      let _ = this;
      let url  = '/advertiser/profile/';
      axios.get(url).then((response) => {
        if (response) {
          
          this.statused = response.data.data.account;
        }
      });
    },

  }
}
</script>
