<template>
  <div>
    <b-row>
      <b-col cols="4">
        <ecommerce-pengajuan
          @updateTable="getRequestVerified"
          :data="{ name: 'prima', saleToday: '2.5' }"
        />
      </b-col>
      <b-col cols="8"> </b-col>
      <b-col cols="4">
        <b-card no-body>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col xl="12">
                <b-media no-body>
                  <b-media-aside class="">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="ActivityIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{totalRun}}</h4>
                    <b-card-text class="font-small-3 mb-0">
                      Total Campaign Berjalan
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

       <b-col cols="4">
        <b-card no-body>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col xl="12">
                <b-media no-body>
                  <b-media-aside class="">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="UserCheckIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{totalWait}}</h4>
                    <b-card-text class="font-small-3 mb-0">
                      Total Campaign Waiting Approval
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

       <b-col cols="4">
        <b-card no-body>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col xl="12">
                <b-media no-body>
                  <b-media-aside class="">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="CheckCircleIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{totalFinish}}</h4>
                    <b-card-text class="font-small-3 mb-0">
                      Total Campaign Selesai
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="12" v-if="items.length > 0">
        <b-card no-body>
          <b-card-title>
            <h5 class="pt-1 pl-1">Riwayat Pengajuan Verified</h5>
          </b-card-title>
          <b-table
            striped
            hover
            responsive
            show-empty
            class="position-relative"
            :fields="fields"
            :items="items"
          >
            <template #cell(action)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
              >
                Lihat Detail
              </b-button>
            </template>

            <template #empty="scope">
              <div role="alert" aria-live="polite">
                <div class="text-center my-2">Tidak ada data</div>
              </div>
            </template>
            <template #emptyfiltered="scope">
              <div role="alert" aria-live="polite">
                <div class="text-center my-2">
                  Data yang dicari tidak ditemukan
                </div>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BTable,
  BFormGroup,
  BButton,
  BCardHeader,
  BCardText,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import EcommercePengajuan from "@/views/dashboard/ecommerce/EcommercePengajuan.vue";
import axios from "@axios";
import Ripple from "vue-ripple-directive";

export default {
  name: "Dashboard",
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BTable,
    BFormGroup,
    BButton,
    EcommercePengajuan,
    BCardHeader,
    BCardText,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: "createdAt", label: "Tanggal" },
        { key: "name", label: "Nama Lengkap" },
        { key: "nama_bisnis", label: "Nama Bisnis" },
        { key: "status_pengajuan", label: "Status Pengajuan" },
      ],
      totalRun:0,
      totalWait:0,
      totalFinish:0
    };
  },
  mounted() {
    let _ = this;
    _.getRequestVerified();
    _.getDashboard();
  },
  methods: {
    getDashboard()
    {
      let _ = this;
        axios
        .get("advertiser/dashboard")
        .then((response) => {
          _.totalRun = response.data.data["on-progress"]
          _.totalWait = response.data.data.review
          _.totalFinish = response.data.data.finished
        })
    },
    getRequestVerified() {
      let _ = this;
      axios
        .get("/advertiser/request-verified")
        .then((resp) => {
          _.items = resp.data.data.data.map((data) => {
            let adv = data.advertiser;
            return {
              createdAt: data.createdAt,
              name: adv.firstName + " " + adv.lastName,
              nama_bisnis: adv.companyName,
              status_pengajuan: data.status.replace(
                /(?:^|\s)\S/g,
                function (a) {
                  return a.toUpperCase();
                }
              ),
            };
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>